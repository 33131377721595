import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route} from 'react-router-dom';

import EN from './containers/EN';
import FR from './containers/FR';

import './index.css';

import * as serviceWorker from './serviceWorker';

setTimeout(() => {
    window.AOS.init({
        offset: 150,
        delay: 0,
        once: true,
    });
}, 1500);

ReactDOM.render(
    <BrowserRouter>
        <div>
            <Route path="/" component={FR} exact />

            <Route path="/en" component={EN} />
            <Route path="/fr" component={FR} />
        </div>
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.unregister();

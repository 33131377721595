import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

import { connect } from '../../store';

import './index.css';


class Header extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    static propTypes = {
        currentLocale: PropTypes.string.isRequired,
    };

    static defaultProps = {
        currentLocale: "fr"
    }

    componentDidMount() {
        window.Navbar();
    };

    componentWillUnmount() {

    };

    render() {
        return (
            <div>
                <header className="spyre-navbar navbar navbar-expand-sm bg-secondary navbar-dark fixed-top align-items-center d-none d-sm-flex"  data-text-color="#ffffff">
                    <div className="container">
                        <a className="navbar-brand mr-lg-5 mr-xl-7" style={{cursor: "pointer"}} onClick={this.goHome}>
                            <img src="/assets/img/logo.png" className="d-none d-lg-block" alt="Guy Brochu" width="183" />
                            <img src="/assets/img/logo.png" className="d-block d-lg-none" alt="Guy Brochu" width="150" />
                        </a>

                        <span className="navbar-text flex-fill d-none d-sm-inline-block text-white">
                            <span className="location-primary pl-3 text-white d-none d-sm-inline-block">
                                <i className="fas fa-globe-americas" style={{verticalAlign: 0}}/>
                                &nbsp;&nbsp;
                                Région du Grand Montréal
                                <br/>
                                <span className="location-secondary">Laurentides, Outaouais, Est de l'Ontario</span>
                            </span>

                            <span className="pl-3 text-white d-none d-md-inline-block">
                                <i className="fas fa-phone" style={{verticalAlign: 0}}/>
                                &nbsp;&nbsp;
                                <a href="tel:+1-514-791-1704">514-791-1704</a>
                                &nbsp;
                            </span>
                        </span>

                        <div className="navbar-text d-none d-sm-flex justify-content-end ml-auto mr-2 text-white">
                            <a href="#" onClick={(e) => {this.switchLocale("en", e)}}>English</a>
                        </div>
                    </div>
                </header>
                
                <header className="spyre-navbar navbar navbar-expand-md bg-secondary navbar-dark fixed-top align-items-center d-flex d-sm-none" data-text-color="#ffffff">
                    <div className="container">
                        <span className="navbar-text flex-fill d-inline-block d-md-none text-white">
                            <span className="location-primary pl-3 text-white d-inline-block">
                                <i className="fas fa-globe-americas" style={{verticalAlign: 0}}/>
                                &nbsp;&nbsp;
                                Région du Grand Montréal
                                <br/>
                                <span className="location-secondary">Laurentides, Outaouais, Est de l'Ontario</span>
                            </span>
                        </span>

                        <div className="navbar-text d-flex d-md-none justify-content-end ml-auto mr-2 text-white">
                            <a href="#" onClick={(e) => {this.switchLocale("en", e)}}>English</a>
                        </div>
                    </div>
                </header>
            </div>
        )
    }

    goHome = () => {
        this.flashLoader();

        setTimeout(() => {
            this.props.history.push("/fr");
        }, 100);
    }

    switchLocale = (locale, e) => {
        e.preventDefault();

        this.flashLoader();

        setTimeout(() => {
            this.props.history.push("/" + locale);
        }, 100)
    }

    flashLoader = () => {
        const $preloader = window.$(".preloader");

        $preloader.css({opacity: 0.6});
        $preloader.fadeIn(200);
        $preloader.delay(200).fadeOut("slow");
        $preloader.css({opacity: 1.0});
    }
}

export default withRouter(connect(({ currentLocale }) => ({ currentLocale }))(Header));

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from '../../store';

import './index.css';


class Home extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    static propTypes = {
        currentLocale: PropTypes.string.isRequired,
    };

    static defaultProps = {
        currentLocale: "fr"
    }

    componentDidMount() {
        window.Parallax();
    };

    componentWillUnmount() {

    };

    render() {
        return (
            <section className="py-0 overflow-hidden text-center">
                <div className="bg-container overlay overlay-80 parallax" data-rellax-percentage="0.6" style={{backgroundImage: "url('/assets/img/bg_front.jpg')"}}></div>
                <div className="container">
                    <div className="row h-100vh justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <img src="/assets/img/logo_square.png" className="d-none d-sm-inline" alt="Guy Brochu" data-aos="flip-right" data-aos-duration="1000" style={{marginBottom: 40}} width="200"/>
                            {/* <img src="/assets/img/logo_square.png" className="d-inline d-sm-none" alt="Guy Brochu" data-aos="flip-right" data-aos-duration="1000" style={{marginBottom: 40}} width="80"/> */}

                            <h1 className="guy text-white text-uppercase fs-5 fs-lg-7" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">Guy Brochu</h1>
                            <p className="mb-5 fs-lg-1 text-white" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">Courtier de matériaux et de main-d'oeuvre</p>

                            <div style={{marginTop: 40}} data-aos="fade-in" data-aos-delay="400" data-aos-duration="1000">
                                <h2 className="mb-4 text-white guy-question">Un projet de construction? Une rénovation?</h2>

                                <p className="mb-4 text-white guy-description">
                                    Simplifiez votre vie! Armez-vous du <strong>réseau professionel de 35 années</strong> de Guy et accédez à une collection de fournisseurs, matériaux et travailleurs sans pareil, le tout <strong>sans payer rien en extra</strong>.
                                </p>

                                <h5 className="mb-4 text-white guy-callout">
                                    Contactez Guy aujourd'hui pour discuter des possibilités et obtenir une estimation GRATUITE.
                                </h5>

                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <a href="tel:+1-514-791-1704" className="btn btn-sm btn-primary px-5 mb-2">
                                            <i className="fas fa-phone" style={{verticalAlign: 0}}/>
                                            &nbsp;&nbsp;
                                            514-791-1704
                                        </a>
                                    </div>
                                    <div className="col-auto">
                                        <a href="mailto:guybrochu@gmail.com" className="btn btn-sm btn-primary px-5 mb-2">
                                            <i className="far fa-envelope" style={{verticalAlign: -1}}/>
                                            &nbsp;&nbsp;
                                            guybrochu@gmail.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="move d-none d-lg-block">
                            <a href="#section-2" className="text-white" data-smooth-scroll data-smooth-scroll-hash="false">
                                <i className="zmdi zmdi-long-arrow-down zmdi-hc-2x"></i>
                            </a>
                        </div> */}
                    </div>
                </div>

                <div className="service-spacer"/>

                <div className="container">
                    <div className="row no-gutters justify-content-between">
                        <div className="col-lg-12">
                            <div className="row">
                                <h2 className="mb-4 text-white" style={{marginLeft:14}}>Services Offerts</h2>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="service bg-secondary text-white mb-4 px-5 pt-5 pb-5">
                                        <h4 className="mb-4 fs-1 fw-medium text-uppercase">Courtier: Matériaux de Construction</h4>
                                        <ul className="mb-0 fs--1">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <li>Béton</li>
                                                    <li>Matériaux Généraux</li>
                                                    <li>Poutrelles en I ou Ajourées</li>
                                                    <li>Fermes de Toit</li>
                                                    <li>Portes Extérieures et Fenestration</li>
                                                    <li>Portes Intérieures et Moulures</li>
                                                    <li>Portes de Garage</li>
                                                    <li>Revêtement Extérieur</li>
                                                    <li>Planchers - Tous les Types</li>
                                                </div>
                                                <div className="col-lg-6">
                                                    <li>Céramique</li>
                                                    <li>Peinture</li>
                                                    <li>Plomberie</li>
                                                    <li>Électricité</li>
                                                    <li>Isolation et Insonorisation</li>
                                                    <li>Panneaux de Gypse (Gyproc)</li>
                                                    <li>Armoires de Cuisine</li>
                                                    <li>Armoires de Salle de Bain</li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>

                                    <div className="service bg-secondary text-white mb-4 px-5 pt-5 pb-5">
                                        <h4 className="mb-4 fs-1 fw-medium text-uppercase">Soutien: Auto-Construction</h4>
                                        <ul className="mb-0 fs--1">
                                            <li style={{"listStyleType": "none"}}><strong>Aide à tous les niveaux!</strong></li>
                                     
                                            <li>Financement</li>
                                            <li>Planification du projet</li>
                                            <li>Préparation des soumissions</li>
                                            <li>Sélection des sous-traitants</li>
                                            <li>Assistance à la gestion de projet</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">        
                                    <div className="service bg-secondary text-white mb-4 px-5 pt-5 pb-5">
                                        <h4 className="mb-4 fs-1 fw-medium text-uppercase">Courtier: Main-d'oeuvre</h4>
                                        <ul className="mb-0 fs--1">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <li>Expertise</li>
                                                    <li>Dessinateur</li>
                                                    <li>Architecte</li>
                                                    <li>Ingénieur</li>
                                                    <li>Arpenteur</li>
                                                    <li>Charpentier</li>
                                                    <li>Électricien</li>
                                                    <li>Plombier</li>
                                                    <li>Peintre</li>
                                                    <li>Couvreur</li>
                                                    <li>Excavation</li>
                                                    <li>Coffrage</li>        
                                                </div>
                                                <div className="col-lg-6">
                                                    <li>Pose: Revêtement Extérieur</li>
                                                    <li>Pose: Panneaux de Gypse</li>
                                                    <li>Pose: Isolation et Insonorisation</li>
                                                    <li>Pose: Finition </li>
                                                    <li>Pose: Planchers</li>
                                                    <li>Pose: Armoires</li>
                                                    <li>Pose: Céramique</li>
                                                    <li>Terrassement</li>
                                                    <li>Ventilation</li>
                                                    <li>Climatisation</li>
                                                    <li>Plancher Chauffant</li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>

                                    <div className="service bg-secondary text-white mb-4 px-5 pt-5 pb-5">
                                        <h4 className="mb-4 fs-1 fw-medium text-uppercase">Terrains à Vendre</h4>
                                        <ul className="mb-0 fs--1">
                                            <li>Résidentiel</li>
                                            <li>Commercial</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    // handleNavigationClick = (route) => {
    //     this.props.history.push("/" + route);
    // }
}

export default connect(({ currentLocale }) => ({ currentLocale }))(Home);

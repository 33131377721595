import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';

import { Provider } from '../../store';

import Header from './header';

import Home from './home';

import './index.css';


class EN extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    componentDidMount() {
        
    };

    componentWillUnmount() {

    };

    render() {
        return (
            <Provider>
                <Header />
                
                <main className="main minh-100vh">
                    <Route path="/" component={Home} exact />   
                    <Route path="/en" component={Home} exact />
                </main>

            </Provider>
        )
    }
}

export default EN;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from '../../store';

import './index.css';


class Home extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    static propTypes = {
        currentLocale: PropTypes.string.isRequired,
    };

    static defaultProps = {
        currentLocale: "en"
    }

    componentDidMount() {
        window.Parallax();
    };

    componentWillUnmount() {

    };

    render() {
        return (
            <section className="py-0 overflow-hidden text-center">
                <div className="bg-container overlay overlay-80 parallax" data-rellax-percentage="0.6" style={{backgroundImage: "url('/assets/img/bg_front.jpg')"}}></div>
                <div className="container">
                    <div className="row h-100vh justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <img src="/assets/img/logo_square.png" className="d-none d-sm-inline" alt="Guy Brochu" data-aos="flip-right" data-aos-duration="1000" style={{marginBottom: 40}} width="200"/>
                            {/* <img src="/assets/img/logo_square.png" className="d-inline d-md-none" alt="Guy Brochu" data-aos="flip-right" data-aos-duration="1000" style={{marginBottom: 40}} width="80"/> */}

                            <h1 className="guy text-white text-uppercase fs-5 fs-lg-7" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">Guy Brochu</h1>
                            <p className="mb-5 fs-lg-1 text-white" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">Building Materials &amp; Workforce Broker</p>

                            <div style={{marginTop: 40}} data-aos="fade-in" data-aos-delay="400" data-aos-duration="1000">
                                <h2 className="mb-4 text-white guy-question">Have a construction project? A renovation?</h2>

                                <p className="mb-4 text-white guy-description">
                                    Simplify your life! Tap into Guy's <strong>professional network of 35 years</strong> and easily gain access to great suppliers, materials and qualified workers at <strong>no extra cost</strong> to you.
                                </p>

                                <h5 className="mb-4 text-white guy-callout">
                                    Contact Guy today to discuss the possibilities &amp; obtain a FREE, no obligation estimate.
                                </h5>

                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <a href="tel:+1-514-791-1704" className="btn btn-sm btn-primary px-5 mb-2">
                                            <i className="fas fa-phone" style={{verticalAlign: 0}}/>
                                            &nbsp;&nbsp;
                                            514-791-1704
                                        </a>
                                    </div>
                                    <div className="col-auto">
                                        <a href="mailto:guybrochu@gmail.com" className="btn btn-sm btn-primary px-5 mb-2">
                                            <i className="far fa-envelope" style={{verticalAlign: -1}}/>
                                            &nbsp;&nbsp;
                                            guybrochu@gmail.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="move d-none d-lg-block">
                            <a href="#section-2" className="text-white" data-smooth-scroll data-smooth-scroll-hash="false">
                                <i className="zmdi zmdi-long-arrow-down zmdi-hc-2x"></i>
                            </a>
                        </div> */}
                    </div>
                </div>

                <div className="service-spacer"/>

                <div className="container">
                    <div className="row no-gutters justify-content-between">
                        <div className="col-lg-12">
                            <div className="row">
                                <h2 className="mb-4 text-white" style={{marginLeft:14}}>Services</h2>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="service bg-secondary text-white mb-4 px-5 pt-5 pb-5">
                                        <h4 className="mb-4 fs-1 fw-medium text-uppercase">Broker: Construction Materials</h4>
                                        <ul className="mb-0 fs--1">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <li>Concrete</li>
                                                    <li>General Materials</li>
                                                    <li>I-Joists &amp; Open Joists</li>
                                                    <li>Roof &amp; Floor Trusses</li>
                                                    <li>Exterior Doors &amp; Windows</li>
                                                    <li>Interior Doors &amp; Mouldings</li>
                                                    <li>Garage Doors</li>
                                                    <li>Exterior Siding</li>
                                                    <li>Flooring - All Types</li>
                                                </div>
                                                <div className="col-lg-6">
                                                    <li>Ceramic Tiles</li>
                                                    <li>Paint</li>
                                                    <li>Plumbing Materials</li>
                                                    <li>Electrical Materials</li>
                                                    <li>Isolation &amp; Soundproofing</li>
                                                    <li>Drywall Panels (Gyproc)</li>
                                                    <li>Kitchen Cabinets</li>
                                                    <li>Bathroom Cabinets</li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                    
                                    <div className="service bg-secondary text-white mb-4 px-5 pt-5 pb-5">
                                        <h4 className="mb-4 fs-1 fw-medium text-uppercase">Support: Self-Builds</h4>
                                        <ul className="mb-0 fs--1">
                                            <li style={{"listStyleType": "none"}}><strong>Help at every major step!</strong></li>
                                     
                                            <li>Financing</li>
                                            <li>Project planning</li>
                                            <li>Obtaining quotes</li>
                                            <li>Subcontractor selection</li>
                                            <li>Project management assistance</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="service bg-secondary text-white mb-4 px-5 pt-5 pb-5">
                                        <h4 className="mb-4 fs-1 fw-medium text-uppercase">Broker: Workforce</h4>
                                        <ul className="mb-0 fs--1">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <li>Expertise</li>
                                                    <li>Construction Designer</li>
                                                    <li>Architect</li>
                                                    <li>Engineer</li>
                                                    <li>Land Surveyor</li>
                                                    <li>Carpenter</li>
                                                    <li>Electrician</li>
                                                    <li>Plumber</li>
                                                    <li>Painter</li>
                                                    <li>Roofer</li>
                                                    <li>Excavation</li>
                                                    <li>Formwork</li>        
                                                </div>
                                                <div className="col-lg-6">
                                                    <li>Installation: Exterior Siding</li>
                                                    <li>Installation: Drywall Panels</li>
                                                    <li>Installation: Isolation &amp; Soundproofing</li>
                                                    <li>Installation: Interior Finishing</li>
                                                    <li>Installation: Flooring</li>
                                                    <li>Installation: Cabinets</li>
                                                    <li>Installation: Tiling</li>
                                                    <li>Landscaping</li>
                                                    <li>Ventilation</li>
                                                    <li>Air Conditioning</li>
                                                    <li>Heated Floors</li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>

                                    <div className="service bg-secondary text-white mb-4 px-5 pt-5 pb-5">
                                        <h4 className="mb-4 fs-1 fw-medium text-uppercase">Land for Sale</h4>
                                        <ul className="mb-0 fs--1">
                                            <li>Residential</li>
                                            <li>Commercial</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    // handleNavigationClick = (route) => {
    //     this.props.history.push("/" + route);
    // }
}

export default connect(({ currentLocale }) => ({ currentLocale }))(Home);

import createStore from 'react-waterfall';


const config = {
    initialState: {
        currentLocale: "en"
    },
    actionsCreators: {
        setCurrentLocale: ({ currentLocale }, func, data) => {
            return {
                currentLocale: data
            };
        }
    },
};

export const { Provider, connect, actions } = createStore(config);